import "./person.scss";
import * as React from "react";
import { TypeAboutUsPersonData } from "../utils/queries/about_us_page";
import { SectionDivider } from "../components/section-divider/section-divider";
import Linked from "../utils/linkedItem";
import Helmet from "react-helmet";
import AmazeePage from "../components/layout";
import { PageTitle } from "../components/title/title";
import RichTextRender from "../utils/rich-text";
import usePreviewData from "../utils/usePreviewData";

const SocialLink = ({ url, icon }: { url: string; icon: string }) => {
  if (url) {
    return (
      <a className="social-url" href={url} target="_blank">
        <img src={icon} />
      </a>
    );
  }
  return null;
};

const AboutUsPersonPage = ({ pageContext, location }: any) => {
  const person = usePreviewData(
    pageContext.data,
    "prismicAboutuspersonpage",
    true
  ) as TypeAboutUsPersonData;
  const names = person.name.split(" ");
  const firstName = names[0];
  const lastName = names.slice(1).join(" ");
  const title = person.meta_title ? person.meta_title : person.name;

  return (
    <AmazeePage location={location}>
      <div id="about-us-person">
        <Helmet>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css"
          />
          <meta property="og:type" content="profile" />
          <meta property="og:title" content={`${person.name} | Amazee.io`} />
          <meta property="og:image" content="http://amazee.io/image" />
          <meta property="profile:first_name" content={firstName} />
          <meta property="profile:last_name" content={lastName} />
          {person.canonical_url && (
            <link rel="canonical" href={person.canonical_url} />
          )}
        </Helmet>
        <PageTitle title={title} amazeeOnly={true} />

        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-10">
              <div className="row">
                {/* Left Column */}
                <div className="col-12 col-lg-4">
                  <div
                    className="headshot"
                    style={{ backgroundImage: `url('${person.headshot.url}')` }}
                  />

                  <div className="social d-flex justify-content-center d-lg-none">
                    <SocialLink
                      url={person.linkedin_url}
                      icon="/images/linkedin-blue.svg"
                    />
                    <SocialLink
                      url={person.twitter_url}
                      icon="https://amazeeio.cdn.prismic.io/amazeeio/b47a43bd-eee4-4fd0-ad21-b13fd16247b1_X.svg"
                    />
                    <SocialLink
                      url={person.github_url}
                      icon="/images/github-blue.svg"
                    />
                  </div>
                </div>

                {/* Text Right column */}
                <div className="col-12 col-lg-7 person-details">
                  <h1 className="name">{person.name}</h1>
                  <SectionDivider />
                  <h6 className="role">{RichTextRender(person.role)}</h6>
                  <div className="description">
                    {RichTextRender(person.description)}
                  </div>

                  <div className="social d-none d-lg-flex">
                    <SocialLink
                      url={person.linkedin_url}
                      icon="/images/linkedin-blue.svg"
                    />
                    <SocialLink
                      url={person.twitter_url}
                      icon="https://amazeeio.cdn.prismic.io/amazeeio/b47a43bd-eee4-4fd0-ad21-b13fd16247b1_X.svg"
                    />
                    <SocialLink
                      url={person.github_url}
                      icon="/images/github-blue.svg"
                    />
                  </div>

                  <Linked
                    link_to={{
                      document: {
                        data: {
                          url: "/about-us?scrollTo=people",
                          is_external: false,
                        },
                      },
                    }}
                    className="link-to-people"
                  >
                    <div className="arrow-left">
                      <i className="fa fa-arrow-left" />
                    </div>
                    <p>Back to Team overview</p>
                  </Linked>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AmazeePage>
  );
};

export default AboutUsPersonPage;
